<template>
  <div class="content md flex-box vertical">
    <data-list
      class="flex-grow"
      ref="dataList"
      :url="url"
      :query="query"
      :requestConfig="{ noTempleFilter: true }"
      entity-name="服务"
      show-row-num
      hide-edit
      hide-delete
      :columns="columns"
      :search-key-type="searchKeyType"
      @detail="showDetail"
    >
      <template v-slot:query="{ form }">
        <a-form-model-item>
          <a-select
            style="width: 180px;"
            placeholder="选择小组筛选"
            show-search
            option-filter-prop="children"
            @change="updateList"
            :options="groupOptions"
            v-model="form.group_id"
            allowClear
          >
          </a-select>
        </a-form-model-item>
        <a-form-model-item>
          <a-select
            style="width: 180px;"
            placeholder="选择上传状态查询"
            @change="updateList"
            :options="uploadStatusOptions"
            v-model="form.status"
            allowClear
          >
          </a-select>
        </a-form-model-item>
        <a-form-model-item>
          <a-date-picker
            v-model="form.service_date"
            :format="format"
            :value-format="format"
            placeholder="选择服务日期"
            @change="updateList"
            allowClear
          />
        </a-form-model-item>
        <a-form-model-item>
          <a-input v-model="form.service_name" placeholder="输入服务名称查询" allowClear></a-input>
        </a-form-model-item>
        <a-form-model-item>
          <!-- <a-input v-model="form.group_leader" placeholder="输入组长名称查询" allowClear></a-input> -->
          <role-select
            v-model="form.group_leader"
            :privilege="9"
            :props="{ placeholder: '请选择组长', allowClear: true, style: { width: '180px' } }"
            @change="updateList"
          ></role-select>
        </a-form-model-item>
      </template>
    </data-list>
  </div>
</template>

<script>
import { uploadStatusOptions } from "../../../common/hr/volunteer";
import { getTemple } from "../../../common/js/storage";
import { dataConvertOptions, getKeyTitle, stringConvertTimestamp, timestampConvertString } from "../../../common/js/tool";

let temple = getTemple();

export default {
  name: "TeamLeaderLog",
  data() {
    return {
      format: "YYYY-MM-DD",
      url: "/admin/volunteer-log",
      searchKeyType: {
        group_id: 2,
        status: 2,
        service_date: function(form, key) {
          let value = form[key];
          let res = "";
          if (value) {
            res = `&${key}=${stringConvertTimestamp(value)}`;
          }
          return res;
        },
        service_name: 2,
        group_leader: 2,
      },
      columns: [
        {
          title: "服务名称",
          customRender: (text) => {
            return <span>{text.volunteerService?.name || "-"}</span>;
          },
        },
        {
          title: "服务日期",
          customRender: (text) => {
            return <span>{timestampConvertString(text.volunteerService?.service_date, "yyyy-MM-dd") || "-"}</span>;
          },
        },
        {
          title: "组长",
          customRender: (text) => {
            return text.volunteerService?.group_leader ? (
              <open-data type="userName" openid={text.volunteerService.group_leader} />
            ) : (
              "-"
            );
          },
        },
        {
          title: "上传状态",
          dataIndex: "status",
          customRender: (text) => {
            return <span>{getKeyTitle(uploadStatusOptions, text, "value", "label")}</span>;
          },
        },
      ],
      groupOptions: null,
      uploadStatusOptions,
    };
  },
  computed: {
    action() {
      return this.$route.meta.action;
    },
    dataList() {
      return this.$refs.dataList;
    },
    query() {
      let res = "&sort=-id&expand=volunteerService,volunteerGroup.groupLeader";
      if (temple) {
        res += `&filter[volunteer_log.temple_id]=${temple}`;
      }
      return res;
    },
  },
  created() {
    this.getGroup();
  },
  methods: {
    getGroup() {
      return this.$axios({
        url: "/admin/volunteer-group",
        method: "GET",
        params: {
          sort: "sort,-id",
          pageSize: 9999,
        },
      }).then((res) => {
        this.groupOptions = dataConvertOptions(res.data);
      });
    },
    updateList() {
      this.dataList.updateList();
    },
    showDetail({ item }) {
      this.$router.push({ name: "TeamLeaderLogDetail", params: { lid: item.id } });
    },
  },
};
</script>

<style lang="less" scoped>
.group-list {
  margin-bottom: 12px;
  flex-shrink: 0;
}
.group-item {
  display: inline-block;
  white-space: nowrap;
  cursor: pointer;
  padding: 2px 8px;
  &.active {
    color: #1890ff;
  }
}
</style>
